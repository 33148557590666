// @import 'colors.scss';

// Catch all if the iframe ever comes back
body
  > iframe[style*='2147483647']:not([id='webpack-dev-server-client-overlay']) {
  display: none;
}

@media (max-width: 991px) {
  *::-webkit-scrollbar {
    width: 0px;
  }
}

// * {
//   border: 1px solid black !important;
// }
